$('.toggle-menu').click(function () {
  $('body').toggleClass('menu-open');
});

$('.slider').slick({
  autoplay: true,
  arrows: false,
//   cssEase: 'ease',
  edgeFriction: 0.5,
//   fade: true,
});

$('.brands-slider-wrapper').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
});

$('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
});

$('#tel_cell').intlTelInput({
  initialCountry: "auto",
  separateDialCode: true,
  formatOnDisplay: false,
  geoIpLookup: function (success,failure) {
    $.get("https://ipinfo.io", function () {}, "jsonp").always(function (resp) {
      var countryCode = (resp && resp.country) ? resp.country : "";
      success(countryCode);
    });
  }
});

$("form").submit(function () {
  var dialCode = $("#tel_cell").intlTelInput("getSelectedCountryData").dialCode;
  $("#tel_cell_country_code").val(dialCode);
})

let getTelCell = document.querySelector("#tel_cell");
let errorMsg = document.querySelector("#error-msg");
let validMsg = document.querySelector("#valid-msg");
let errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

let reset = function () {
  validMsg.innerHTML = "The number seems good";
  errorMsg.classList.add("d-none");
  validMsg.classList.add("d-none");
};

if (getTelCell) {
  getTelCell.addEventListener('blur', function () {
    reset();
    if (getTelCell.value.trim()) {
      var num = $("#tel_cell").intlTelInput("isValidNumber");
      if (num) {
        validMsg.classList.remove("d-none");
      } else {
        mobile.classList.add("d-error");
        var errorCode = $("#tel_cell").intlTelInput("getValidationError");
        errorMsg.innerHTML = errorMap[errorCode];
        errorMsg.classList.remove("d-none");
      }
    }
  });
  getTelCell.addEventListener('change', reset);
  getTelCell.addEventListener('keyup', reset);
}

$('.products .product .inner, .category-item .category-item-inner').matchHeight();

$('.image-nav').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.image-slider',
    focusOnSelect: true,
    mobileFirst: true,
    arrows: true,
    prevArrow: '<div class="slick-prev"><i class="fas fa-angle-left" aria-hidden="true"></i></div>',
    nextArrow: '<div class="slick-next"><i class="fas fa-angle-right" aria-hidden="true"></i></div>',
    infinite: false,
});

$('.image-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.image-nav',
    cssEase: 'ease',
    speed: 500,
    centerPadding: 0,
    infinite: false,
    focusOnSelect: true,
});

$('.file-upload').fileupload({
    headers: {
        'X-CSRF-Token': $('input[name="_token"]').val()
    },
    // dataType: 'json',
    add: function (e, data) {
        // files: filesList,
        data.submit();
        let input_name = $(this).attr('name');
        let icon_name = $(this).attr('data-icon');
        console.log('1',input_name);
        $('.' + input_name + '_progress .progress-bar').css(
            'width', 0 + '%'
        );
        $('.' + input_name + '_icon').removeClass('d-none');
        $('.' + input_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
    },
    progressall: function (e, data) {
        let input_name = $(this).attr('name');
        let icon_name = $(this).attr('data-icon');
        console.log('2',input_name);
        let progress = parseInt(data.loaded / data.total * 100, 10);
        $('.' + input_name + '_icon').removeClass('d-none');
        $('.' + input_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
        $('.' + input_name + '_progress').removeClass('d-none').fadeIn();
        $('.' + input_name + '_progress .progress-bar').css(
            'width', progress + '%'
        );
        if (progress == 100) {
            $('.' + input_name + '_progress').fadeOut();
        }
    },
    done: function (e, data) {
        console.log(data.result.status);
        // $('#items').empty().append(data.result);
        let input_name = $(this).attr('name');
        let icon_name = $(this).attr('data-icon');
        console.log('3',input_name);
        // console.log(input_files,data._response.result.status);
        if (data.result.status == 'failed') {
            console.log('failed',input_name);
            $('.' + input_name + '_error').empty().append(data._response.result.message).removeClass('d-none');
            $('.' + input_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
        }
        if (data.result.status == 'success') {
            console.log('success',input_name);
            // console.log(data._response.result.file_url);
            $('.' + input_name + '_preview').removeClass('d-none').attr('src', data._response.result.file_url).parent().attr('href', data._response.result.file_url);
            $('.' + input_name + '_hide').removeClass('d-none');
            $('.' + input_name + '_icon').addClass('d-none');
            $('.' + input_name + '_error').addClass('d-none');
        }
    },
});

$('.remove-file').on('click', function () {
    let fileName = $(this).attr('data-name');
    let input_name = $(this).attr('data-toggle');
    let icon_name = $(this).attr('data-icon');
    let url = $(this).attr('data-url');
    let fileId = $(this).attr('data-id');
    $.confirm({
        title: 'Are you sure to remove?',
        content: 'Confirm to delete this' + ' <strong>' + fileName + '</strong> ' + 'permanently.',
        type: 'blue',
        buttons: {
            ok: {
                text: "Confirm",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function () {
                    if(fileId){
                        $('.'+input_name+ '_' + fileId).addClass('d-none');
                        $('.'+input_name +'_icon_' + fileId).removeClass('d-none').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
                    }else{
                        $('.' + input_name + '_icon').removeClass(icon_name).addClass('fas fa-spinner fa-spin');
                        $('.' + input_name + '_icon').removeClass('d-none');
                    }
                    $.get(url, function (data) {
                        if (data == 'success') {
                            $('.' + input_name + '_preview').addClass('d-none').attr('src', null);
                            $('.' + input_name + '_hide').addClass('d-none');
                            $('.' + input_name + '_icon').removeClass('d-none');
                            $('.' + input_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
                        }
                        if (data == 'files_success') {
                            $('.' + fileId + '_image').addClass('d-none');
                            $('.' + input_name + '_icon').removeClass('d-none');
                            $('.' + input_name + '_icon').removeClass('fas fa-spinner fa-spin').addClass(icon_name);
                        }
                    })
                }
            },
            cancel: function () {}
        }
    });
});
